<template>
  <!-- <div
    style="width: 100%;height:80vh"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular
      color="primary"
      indeterminate
      size="30"
    ></v-progress-circular>
  </div> -->
  <div>
    <div
      style="width: 100%;height:80vh"
      class="d-flex justify-center align-center"
      v-if="loading && !overlayValue"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="30"
      ></v-progress-circular>
    </div>
    <v-overlay v-if="overlayValue && !loading" :value="overlayValue">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: ['loading', 'overlayValue'],
};
</script>

<style></style>
